@media (min-width: 992px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto;
  margin-right: auto;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

#mainNav {
  background-color: #fff !important;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  padding: 8px 16px;
}

@media (min-width: 992px) {
  #mainNav {
    border-color: #f1f3f5;
    background-color: transparent;
  }
}

@media (min-width: 1200px) {
  #mainNav {
    border-bottom: 1px solid #f1f3f5;
  }
}

#mainNav .navbar-brand {
  font-weight: 700;
  text-transform: uppercase;
  color: #8493a8;
  font-family: 'Roboto Condensed', sans-serif;
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
}

.navbar-brand {
  display: inline-block;
  margin: 4px;
  padding: 5px 0;
  line-height: inherit;
  white-space: nowrap;
  font-size: 25px;
}

#nav-logo {
  background-image: url(../../img/logo.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 190px;
  height: 48px;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
}

.ml-auto {
  margin-left: auto !important;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

@media (min-width: 992px) {
  #mainNav .navbar-nav > li.nav-item > a.nav-link {
    padding: 0.5rem 1rem;
  }
}

#mainNav .navbar-nav > li.nav-item > a.nav-link,
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus {
  font-family: 'Roboto Condensed', 'Helvetica Neue', Arial, sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 25px;
  color: #3d4d65;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

a {
  font-family: 'Roboto Condensed', sans-serif;
  color: #8493a8;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

.navbar-shrink {
  border-bottom: 1px solid #f1f3f5 !important;
}

li a:focus {
  color: #400461 !important;
  border-bottom: 2px solid #400461 !important;
}

li a:hover {
  color: #400461 !important;
}
