h4 {
  font-family: 'Montserrat';
  font-weight: 600;
}

p {
  font-family: 'Roboto Condensed', sans-serif;
  margin: 0;
}

.services-item {
  padding: 20px;
  border-radius: 4px;
  margin: 15px 0;
  box-shadow: 0 0 12px #f4f4f4;
  background-color: #fff;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.services-item .icon {
  border: 1px solid #f1f1f1;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.services-item h4 {
  font-family: 'Roboto Condensed', sans-serif;
  margin: auto;
}

.centre {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.services-item .services-content h3 {
  margin-top: 10px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #222;
  font-size: 38px;
  font-family: 'Montserrat';
}
