h1 {
  font-family: 'Montserrat';
  font-weight: 700;
}

p {
  font-family: 'Roboto Condensed', sans-serif;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.section-container {
  background-color: #400461;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative !important;
  overflow: hidden !important;
}

.section-container .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.product-device {
  position: absolute;
  right: 10%;
  bottom: -30%;
  width: 300px;
  height: 540px;
  background-color: #333;
  border-radius: 21px;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.product-device::before {
  position: absolute;
  top: 10%;
  right: 10px;
  bottom: 10%;
  left: 10px;
  content: '';
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.product-device-2 {
  top: -25%;
  right: auto;
  bottom: 0;
  left: 5%;
  background-color: #e5e5e5;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

@media (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }
}

@media (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }
}

.image {
  height: 50px;
  margin: 15px;
}

.banner img {
  width: 100%;
  margin-top: 70px;
}
