.App {
  text-align: center;
}

.section-padding {
  padding: 60px 0;
}

.section-header {
  margin-bottom: 30px;
}

.section-title {
  font-size: 30px;
  color: #222222;
  display: inline-block;
  font-weight: 700;
  position: relative;
  font-family: 'Montserrat';
}

.fade-in-down {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.section-animated {
  visibility: visible;
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.shape {
  height: 2px;
  margin: 0 auto 30px;
  position: relative;
  width: 60px;
  background-color: #400461;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.bg-gray {
  background: #f9f9f9;
}

.copyright-content {
  border-top: 1px solid #400461;
  padding: 15px 0;
  margin-top: 45px;
}
