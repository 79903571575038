.content {
  margin: 30px 0;
}

.content h3 {
  font-family: 'Montserrat';
  font-weight: 600;
  color: #400461;
}

.content h4 {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 100;
}
