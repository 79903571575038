.btn.disabled,
.btn:disabled {
  opacity: 1;
}

.btn-common:hover {
  color: #400461 !important;
  background: 0 0;
  border: 1px solid #400461;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}

.btn:focus,
.btn:active {
  box-shadow: none;
  outline: none;
  color: #fff;
}

.btn-common {
  background-color: #400461;
  position: relative;
  z-index: 1;
  text-transform: uppercase;
}

.btn {
  font-size: 14px;
  padding: 10px 30px;
  cursor: pointer;
  font-weight: 400;
  color: #fff;
  border-radius: 30px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  display: inline-block;
}
